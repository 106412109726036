<script lang="ts" setup>
import { matCalculate } from '@quasar/extras/material-icons'
import RoiBody from './Body.vue'
import RoiHeader from './Header.vue'
import '../assets/style.scss'

const roiDialog = ref(false)
const emitter = useEmitter()

emitter.on('closeCalculator', () => {
  roiDialog.value = false
})

function roiHandler() {
  roiDialog.value = !roiDialog.value
  Analytics.track({ event: 'click', action: 'roiHandler', label: 'Open roi calculator' })
}
</script>

<template>
  <q-btn
    v-bind="$attrs"
    class="sol-calculator-btn row"
    color="main-gray"
    text-color="white"
    size="md"
    :ripple="false"
    @click="roiHandler"
  >
    <q-icon size="42px" :name="matCalculate" color="white" />
    <span class="q-mx-auto">{{ $t('roi.title') }}</span>
  </q-btn>
  <q-dialog
    v-model="roiDialog"
    class="sol-calculator"
    transition-duration="150"
    transition-show="fade"
    transition-hide="fade"
  >
    <div class="sol-calculator__main">
      <roi-header />
      <roi-body />
    </div>
  </q-dialog>
</template>
