<script lang="ts" setup>
import { evaRefresh } from '@quasar/extras/eva-icons'
import { lamportsToSol, longPriceFormatter } from '~/utils'

const directStakeStore = useDirectStakeStore()
const stakes = computed(() => directStakeStore.directStakes)
const loading = computed(() => directStakeStore.loading)
const totalDirectStake = computed(() => stakes.value.reduce((acc, s) => acc += +s.availableAmount, 0))
const formatPrice = (v: number) => longPriceFormatter.format(lamportsToSol(v))
</script>

<template>
  <div class="wallet-balance__staking__title row">
    <div class="col-sm-4 col-xs text-left">
      {{ $t('other.myDirectStake') }}
    </div>

    <q-btn class="wallet-balance__staking__refresh-btn q-ml-auto" unelevated color="primary" @click="directStakeStore.getStakes">
      <q-icon size="28px" :name="evaRefresh" color="secondary" />
    </q-btn>

    <div class="col-xs col-sm-5">
      <span v-show="!loading">{{ formatPrice(totalDirectStake) }} JSOL</span>
    </div>
  </div>
</template>
