<script lang="ts">
import { useCoinRateStore } from '~/store'
import { formatAmount, formatMoney } from '~/utils'

export default defineComponent({
  props: {
    name: {
      type: String,
    },
    sol: {
      type: Number,
      default: () => -1,
    },
    isTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const coinRateStore = useCoinRateStore()
    // noinspection TypeScriptUnresolvedVariable
    return {
      solShow: computed(() => (props.sol >= 0 ? formatAmount(Number(props.sol), 3) : '')),
      moneyShow: computed(() =>
        props.sol >= 0 ? formatMoney(props.sol * coinRateStore.solana?.price) : '',
      ),
    }
  },
})
</script>

<template>
  <div
    class="sol-calculator__results__table__row"
    :class="{ 'sol-calculator__results__table__row--bold': isTitle }"
  >
    <div class="sol-calculator__results__table__text">
      {{ name }}
    </div>
    <div class="sol-calculator__results__table__text">
      {{ solShow ? `${solShow} SOL` : '' }}
    </div>
    <div class="sol-calculator__results__table__text">
      {{ solShow ? `$ ${moneyShow}` : '' }}
    </div>
  </div>
</template>
