<script lang="ts" setup>
defineProps({
  text: {
    type: String,
    default: 'Network connection problem.',
  },
})

const isOnline = useOnline()
</script>

<template>
  <q-slide-transition>
    <div v-if="!isOnline" :class="$style.root">
      {{ text }}
    </div>
  </q-slide-transition>
</template>

<style module lang="scss">
  .root {
  position: relative;
  width: 100%;
  padding: 5px;
  background: $negative;
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  z-index: 1;
  text-align: center;
}
</style>
