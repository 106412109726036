<script lang="ts">
import { useRoiCalculator } from '~/features/roi-calculator'
import { isInvalidFloat } from '~/utils'

export default defineComponent({
  setup() {
    const { investmentAmount } = useRoiCalculator()
    watch(investmentAmount, (val) => {
      if (val && Number(val) < 0) {
        investmentAmount.value = '0'
      }
    })
    return {
      investmentAmount,
      isInvalid: computed(
        () =>
          investmentAmount.value
          && (isInvalidFloat(investmentAmount.value) || Number(investmentAmount.value) < 0),
      ),
    }
  },
})
</script>

<template>
  <div class="sol-calculator__form__group">
    <div class="sol-calculator__form__label">
      {{ $t('roi.investmentAmount') }}
    </div>
    <input
      v-model="investmentAmount"
      type="number"
      class="sol-calculator__form__input"
      :class="{ 'sol-calculator__form__input--invalid': isInvalid }"
    >
  </div>
</template>
