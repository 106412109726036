<script lang="ts" setup>
import type { Route } from './NavigationTabs.vue'

defineProps({
  page: {
    type: Object as PropType<Route>,
    required: true,
  },
})

const emit = defineEmits(['closeMenu'])

function closeMenu() {
  emit('closeMenu')
}
</script>

<template>
  <a v-if="page.isLink" :href="page.route" target="_blank" class="navigation-tabs__item" @click="closeMenu">
    <span>
      <img v-if="page.icon" :src="page.icon" alt="page icon" class="q-icon text-body1 q-mr-sm">
      {{ page.name }}
    </span>
    <q-badge v-if="page.badgeNew" color="negative" class="navigation-tabs__item__badge">
      {{ $t('common.new') }}
    </q-badge>
  </a>
  <router-link v-else :to="page.route" class="navigation-tabs__item navigation-tabs__route" @click="closeMenu">
    <span>
      <img v-if="page.icon" :src="page.icon" alt="page icon" class="q-icon text-body1 q-mr-sm" :class="{ [page.iconColor!]: page.iconColor }">
      {{ page.name }}
    </span>
    <q-badge v-if="page.badgeNew" color="negative" class="navigation-tabs__item__badge">
      {{ $t('common.new') }}
    </q-badge>
  </router-link>
</template>
