<script setup lang="ts">
import { matAccountBalanceWallet, matClose } from '@quasar/extras/material-icons'
import { RoiCalculator } from '~/features/roi-calculator'

const route = useRoute()
const visible = ref(false)

function toggleSidebar() {
  visible.value = !visible.value
  Analytics.track({ event: 'Open/Close sidebar', action: 'sidebarHandler', label: 'Open/Close sidebar' })
}

function handleClick(e: any) {
  const isOutsideClick = e?.srcElement?.id === 'app-sidebar-wrapper'
  if (isOutsideClick) {
    toggleSidebar()
  }
}
const isHoldersClub = computed(() => route.path?.includes('holders-club'))
</script>

<template>
  <q-btn
    v-bind="$attrs"
    dense
    unelevated
    class="q-pa-sm"
    color="secondary"
    @click="toggleSidebar"
  >
    <q-icon size="38px" :name="matAccountBalanceWallet" color="black" />
  </q-btn>
  <q-icon
    :name="matClose"
    class="app-sidebar__close"
    :class="{ 'app-sidebar__close--active': visible }"
    @click="visible = false"
  />
  <div
    id="app-sidebar-wrapper"
    class="app-sidebar-wrapper"
    :class="{ 'app-sidebar-wrapper--active': visible }"
    @click="handleClick"
  >
    <div class="app-sidebar">
      <div class="row justify-between">
        <cluster-selector
          class="app-sidebar__cluster-selector"
          :class="{
            'q-mr-md': $q.screen.gt.xs,
            'q-mb-md': $q.screen.lt.sm,
          }"
        />

        <login-button v-if="isHoldersClub" class="app-sidebar__login-btn" />
        <connect-wallet
          v-else
          class="app-sidebar__wallet-btn"
          :class="{
            'q-ml-md': $q.screen.gt.xs,
          }"
          only-disconnect
        />
      </div>

      <wallet-address class="q-my-lg" />

      <wallet-balance connect-wallet-class="app-sidebar__wallet-balance-connect q-mt-lg full-width" />

      <!-- <referral-program class="q-mt-lg" /> -->

      <solana-price-card class="q-mr-auto q-mt-lg q-mb-sm" />

      <!-- <pool-info-card class="q-mt-lg q-mb-lg" /> -->

      <roi-calculator class="q-mt-auto" />
    </div>
  </div>
</template>
