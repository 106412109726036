import { useApyStore } from '~/store'

const epochHours = ref(60) // hours
const investmentAmount = ref<string>('100')
const investmentTime = ref<string>('12')
const investmentPeriod = ref('Month')
const totalAmount = ref(0)
const solDay = ref(0)
const solMonth = ref(0)
const solYear = ref(0)

function getPeriodModifier(val: string, epochHours: number): number {
  switch (val) {
    case 'Year':
      return 1
    case 'Month':
      return 1 / 12
    default:
      return (0.002_739_7 * epochHours) / 24
  }
}

export function useRoiCalculator() {
  const apyStore = useApyStore()
  const apy = computed(() => apyStore.apy.staking + apyStore.apy.jito)

  function calc() {
    const amount = Number(investmentAmount.value)
    const period
      = Number(investmentTime.value) * getPeriodModifier(investmentPeriod.value, epochHours.value)
    const epochFreq = (365 / epochHours.value) * 24
    const epochsPerYear = (365 * 24) / epochFreq

    const apr = ((1 + apy.value) ** (1 / epochsPerYear) - 1) * epochsPerYear
    // const resultSol = amount * Math.pow(1 + apy.value / epochFreq, epochFreq * period);
    const resultSol = amount * (1 + apr / epochFreq) ** (epochFreq * period)

    totalAmount.value = resultSol
    const interest = resultSol - amount

    solYear.value = interest / period
    solMonth.value = solYear.value / 12
    solDay.value = solYear.value / 365
  }

  return {
    apy,
    epochHours,
    investmentAmount,
    investmentTime,
    investmentPeriod,
    totalAmount,
    solDay,
    solMonth,
    solYear,
    calc,
  }
}
