<script lang="ts" setup>
const { i18next } = useTranslation()

const labels = {
  en: 'English',
  de: 'Deutsch',
  jp: '日本語',
  id: 'Indonesian',
  ru: 'Русский',
  ua: 'Українська',
} as const

const keys = Object.keys(labels)
const languages = computed(() => keys.filter(key => key in i18next.store.data))
const currentLanguage = computed(() => i18next.resolvedLanguage)
const fallbackLng = i18next.store.options.fallbackLng?.[0] ?? keys[0]

function handleLanguage(lang: string) {
  i18next.changeLanguage(lang.toLowerCase())
}

if (isClient) {
  watch(currentLanguage, (newLang) => {
    document.body.classList.remove(...keys)
    document.body.classList.add(newLang ?? fallbackLng)
  }, { immediate: true })
}
</script>

<template>
  <q-btn-dropdown
    class="select-language"
    content-class="select-language__menu"
    unelevated
  >
    <template #label>
      <span class="select-language-label">
        {{ currentLanguage }}
      </span>
    </template>
    <q-list>
      <q-item
        v-for="lang in languages"
        :key="lang"
        v-close-popup
        clickable
        dense
        @click="handleLanguage(lang)"
      >
        <q-item-section>
          <q-item-label>{{ labels[lang] }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>
