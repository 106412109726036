<script lang="ts" setup>
import jpointIcon from '~/assets/img/icon/j-points.svg'
import { formatPrice } from '~/utils'

const isSkeleton = ref(true)

const jpStore = useJpointsAnimationStore()
const membershipStore = useMembershipStore()

const todayPoints = ref(0)
const rewardAmount = computed(() => {
  const allPoints = membershipStore?.state.userData?.pointsEarned ?? 0
  return formatPrice((Number(todayPoints.value) + allPoints).toFixed(5))
})

watchDebounced(() => jpStore?.currentPoints, (p) => {
  if (p) {
    todayPoints.value = p
  }
  isSkeleton.value = false
}, { debounce: 1000, immediate: true })
</script>

<template>
  <div class="reward-info">
    <div class="reward-info__row">
      <div class="column">
        <div class="reward-info__title">
          rewards
        </div>
        <div class="reward-info__amount">
          <q-skeleton v-if="isSkeleton" width="70px" height="21px" />
          <span v-else>{{ rewardAmount }}</span>
        </div>
      </div>
      <router-link :to="{ path: '/holders-club/rewards' }">
        <img :src="jpointIcon" alt="jpoint icon">
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.reward-info {
  margin: -4px 14px 0 0;

  @media (max-width: $breakpoint-md) {
    margin: 0;
    min-width: 200px;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: $breakpoint-md) {
      justify-content: flex-end;
    }
  }

  &__title {
    color: $gray-2;
    text-align: right;
    font-size: 9px;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    padding-right: 3px;

    @media (max-width: $breakpoint-md) {
      color: $gray-4;
    }
  }

  &__amount {
    color: $primary;
    text-align: right;
    font-size: 20px;
    font-weight: 500;
    line-height: 21px;

    @media (max-width: $breakpoint-md) {
      color: #fff;
    }

    .q-skeleton {
      border-radius: 4px;
    }
  }

  .column {
    margin-top: -6px;
  }

  a {
    width: 40px;
    height: 40px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

body.body--dark {
  .reward-info__title {
    color: $gray-4;
  }
  .reward-info__amount {
    color: #fff;
  }
}
</style>
