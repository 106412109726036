<script lang="ts" setup>
import { useRoiCalculator } from '../../hooks'
import TableRow from './TableRow.vue'

const { totalAmount, solDay, solMonth, solYear } = useRoiCalculator()
</script>

<template>
  <div class="sol-calculator__results__table">
    <table-row :name="$t('roi.table.totalReturn')" :sol="totalAmount" :is-title="true" />
    <table-row :name="$t('roi.table.interestPerDay')" :sol="solDay" />
    <table-row :name="$t('roi.table.interestPerMonth')" :sol="solMonth" />
    <table-row :name="$t('roi.table.interestPerYear')" :sol="solYear" />
    <table-row />
  </div>
</template>
