<script lang="ts">
import type { StakeAccount } from '~/store'

export default defineComponent({
  emits: [
    'beforeDeposit',
    'afterDeposit',
    'beforeDeactivate',
    'afterDeactivate',
    'beforeWithdraw',
    'afterWithdraw',
  ],
  setup(_props, { emit }) {
    const stakeAccounts = useStakeAccountStore()
    const loading = computed(() => stakeAccounts.loading)
    const loadingPubkeys = computed(() => stakeAccounts.loadingPubkeys)

    return {
      loading,
      loadingPubkeys,

      accounts: computed(() => {
        return stakeAccounts.accountsFull
      }),

      deposit: async ({ stakeAccount, directTarget }: { stakeAccount: StakeAccount, directTarget?: string }) => {
        emit('beforeDeposit')
        await stakeAccounts.depositJpool([stakeAccount], directTarget)
        Analytics.track({ event: 'Deposit JSOL from sidebar', action: 'deposit', label: 'Deposit JSOL from sidebar' })

        emit('afterDeposit')
      },

      deactivate: async (address: string) => {
        emit('beforeDeactivate')
        await stakeAccounts.deactivate([address])
        Analytics.track({ event: 'Deactivate stake account from sidebar', action: 'deactivate', label: 'Deactivate stake account from sidebar' })
        emit('afterDeactivate')
      },

      withdraw: async (address: string, lamports: number) => {
        emit('beforeWithdraw')
        await stakeAccounts.withdraw([{ address, lamports }])
        Analytics.track({ event: 'Withdraw JSOL from sidebar', action: 'unstake', label: 'Withdraw JSOL from sidebar' })
        emit('afterWithdraw')
      },
    }
  },
})
</script>

<template>
  <q-card-section v-if="loading" class="flex flex-center text-primary" style="padding: 24px;">
    <q-skeleton height="20px" width="100%" class="q-mb-sm" />
    <q-skeleton height="20px" width="100%" />
  </q-card-section>

  <template v-else>
    <q-list v-if="accounts.length > 0" class="stake-accounts-list">
      <stake-account-item
        v-for="acc in accounts" :key="acc.stakeAccount.pubkey.toBase58()"
        :stake-account="acc.stakeAccount"
        :status="acc.state"
        :loading="loadingPubkeys.has(acc.stakeAccount.pubkey.toBase58())"
        @deactivate="deactivate"
        @withdraw="withdraw"
        @deposit="deposit"
      />
    </q-list>
    <div v-else class="flex flex-center q-pa-lg text-primary">
      <div v-html="$t('sidebar.noAccounts')" />
    </div>
  </template>
</template>
