<script lang="ts" setup>
const directStakeStore = useDirectStakeStore()
const stakes = computed(() => directStakeStore.directStakes)
const loading = computed(() => directStakeStore.loading)
const activeStakeId = ref(0)

const { withdrawing, setAmount, withdraw, useWithdrawSol } = useWithdraw()

async function withdrawStake(props: { stakeId: number, forceDelayed: boolean }) {
  try {
    activeStakeId.value = props.stakeId
    await withdraw(props.forceDelayed, props.stakeId)
  } catch (error) {
    console.error(error)
  } finally {
    activeStakeId.value = 0
  }
}
</script>

<template>
  <q-card-section v-if="loading" class="flex flex-center text-primary" style="padding: 24px;">
    <q-skeleton height="20px" width="100%" class="q-mb-sm" />
    <q-skeleton height="20px" width="100%" />
  </q-card-section>

  <q-list v-else-if="stakes.length > 0" class="stake-accounts-list">
    <sidebar-direct-stake
      v-for="acc in stakes" :key="acc.id"
      :stake="acc"
      :withdrawing="withdrawing"
      :instant="useWithdrawSol"
      :loading="activeStakeId === acc.id"
      @withdraw="withdrawStake"
      @set-amount="setAmount"
    />
  </q-list>
  <div v-else class="flex flex-center q-pa-lg text-primary">
    <div v-html="$t('sidebar.noDirectStake')" />
  </div>
</template>
