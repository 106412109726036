<script lang="ts">
export default defineComponent({
  props: {
    tpsThreshold: {
      type: Number,
      default: 1500,
    },
  },
  setup(props) {
    const epochStore = useEpochStore()
    const tpsLoading = computed(() => epochStore.loading)
    const epochInfo = computed(() => epochStore.epochInfo)
    const tps = computed(() => epochInfo.value?.tps ?? 0)
    const style = ref()
    const message = ref()

    const { t } = useTranslation()

    const handleTps = () => {
      let msg: string | undefined, backgroundColor: string | undefined
      console.log('[TPS]:', tps.value)

      if (tpsLoading.value) {
        msg = undefined
      } else if (tps.value <= 0) {
        msg = t('network.failure')
        backgroundColor = 'var(--q-negative)'
      } else if (tps.value < props.tpsThreshold) {
        msg = t('network.experiencing')
        backgroundColor = 'var(--q-info)'
      } else {
        msg = undefined
      }

      message.value = msg
      style.value = {
        backgroundColor: backgroundColor ?? 'none',
        height: msg ? 'auto' : 0,
        padding: msg ? '5px' : 0,
      }
    }

    if (!import.meta.env.SSR) {
      watch(tps, async () => {
        setTimeout(handleTps, 500)
      }, { immediate: true })
    }

    return {
      style,
      message,
      isClient,
    }
  },
})
</script>

<template>
  <q-slide-transition>
    <div v-if="message && isClient" :class="$style.root" :style="style">
      {{ message }}
    </div>
  </q-slide-transition>
</template>

<style module lang="scss">
  .root {
  //position: fixed;
  position: relative;
  width: 100%;
  //bottom: -20px;
  //top: 0;
  padding: 5px;
  background: rgba($accent, 0.94);
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  z-index: 1;
  text-align: center;
}
</style>
