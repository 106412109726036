<script lang="ts" setup>
import { formatMoney } from '~/utils'

const coinRateStore = useCoinRateStore()
// noinspection TypeScriptUnresolvedVariable
const solPriceShow = computed(() => formatMoney(coinRateStore.solana?.price))
</script>

<template>
  <div class="sol-calculator__results__notice-title">
    <div class="sol-calculator__results__notice-text sol-calculator__results__notice-text--bold">
      {{ $t('roi.noticeTitle') }}
    </div>
    <div class="sol-calculator__results__notice-text sol-calculator__results__notice-text--light">
      {{ $t('roi.price') }}: 1 SOL = {{ solPriceShow }} USD
    </div>
  </div>
</template>
