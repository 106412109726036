<script lang="ts">
import { useRoiCalculator } from '~/features/roi-calculator'
import { isInvalidFloat, isInvalidTime } from '~/utils'

export default defineComponent({
  setup() {
    const { investmentAmount, investmentTime, investmentPeriod, calc } = useRoiCalculator()

    calc()
    return {
      investmentAmount,
      investmentTime,
      investmentPeriod,
      isDisabled: computed(
        () =>
          isInvalidTime(investmentTime.value, investmentPeriod.value)
          || isInvalidFloat(investmentAmount.value)
          || Number(investmentAmount.value) < 0
          || Number(investmentTime.value) < 0,
      ),
      calc,
    }
  },
})
</script>

<template>
  <q-btn
    class="q-mt-sm sol-calculator__form__btn"
    padding="none"
    label="calculate"
    flat
    :ripple="false"
    :disable="isDisabled"
    @click="calc"
  />
</template>
