<script lang="ts">
import { evaClose } from '@quasar/extras/eva-icons'
import { useEmitter } from '~/hooks'

export default defineComponent({
  setup() {
    const emitter = useEmitter()

    return {
      evaClose,
      close: () => emitter.emit('closeCalculator'),
    }
  },
})
</script>

<template>
  <div class="sol-calculator__header">
    <h1 class="sol-calculator__header__title">
      {{ $t('roi.fullTitle') }}
    </h1>
    <q-btn padding="sm" color="transparent" unelevated :icon="evaClose" size="sm" @click="close" />
  </div>
</template>
